import React, { useContext } from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Navbar from "./components/navigation/navbar/Navbar";
import Footer from "./components/navigation/footer/Footer";
import Home from "./routes/home/Home";
import Login from "./routes/auth/login/Login";
import AuthContext from "./contexts/AuthContext";
import Profile from "./routes/profile/Profile";
import Plans from "./routes/plans/Plans";
import Customizer from "./routes/customizer/Customizer";

const App = () => {
	const { is_login } = useContext(AuthContext);
	return (
		<BrowserRouter>
			<Navbar />
			<Routes>
				<Route path="/" element={<Home />} />
				{is_login() ? (
					<>
						<Route path="/profile" element={<Profile />} />
					</>
				) : (
					<>
						<Route path="/login" element={<Login />} />
					</>
				)}
				<Route path="/plans" element={<Plans />} />
				<Route path="/customizer" element={<Customizer />} />
			</Routes>
			<Footer />
		</BrowserRouter>
	);
};

export default App;
