import React from "react";
import { useTranslation } from "react-i18next";
import PricingCard from "../pricing_card/PricingCard";

const BasicCard = ({ yearlyPaid }) => {
	const { t } = useTranslation("home");

	return (
		<PricingCard
			title={t("pricing_basic_plan_title")}
			description={t("pricing_basic_plan_description")}
			price={yearlyPaid ? 9 : 12}
			include_text={t("pricing_basic_plan_include_title")}
			popular
			price_id={
				yearlyPaid
					? "price_1NcRdfJKfmsW67gbA6mKSJla"
					: "price_1NcRdfJKfmsW67gbjxrBifvu"
			}
			button={t("pricing_basic_plan_button")}
			features={[
				t("pricing_basic_plan_include_1"),
				t("pricing_basic_plan_include_2"),
				t("pricing_basic_plan_include_3"),
			]}
			plan={"basic"}
		/>
	);
};

export default BasicCard;
