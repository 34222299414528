import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import axios, {
	baseURL,
	set_instance_token,
	unset_instance_token,
} from "../services/axios";
import auth_axios from "../services/auth_axios";
import { getSpecificData, storeData } from "../helpers/store_data";
import Loading from "../components/main/loading/Loading";

const AuthContext = createContext();

export const AuthProvider = (props) => {
	const [user, setUser] = useState({
		first_name: "Anonymous",
		last_name: "User",
		token: null,
		quizzes: [],
	});

	const profile = async (token) => {
		const res = await axios
			.get(`/me`)
			.then((e) => e.data)
			.catch((e) => null);
		if (res) {
			setUser({ ...res, token: token });
		} else {
			logout();
		}
	};

	const login = async (email, password) => {
		const res = await auth_axios
			.post(`/login`, {
				email: email,
				password: password,
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (!res) {
			logout();
			return false;
		} else {
			storeData("token", res);
			set_instance_token(res);
			await profile(res);
			return true;
		}
	};

	const login_google = async (token) => {
		const res = await auth_axios
			.post(`/login-google`, {
				email: token,
				password: "",
			})
			.then((e) => e.data)
			.catch((e) => null);
		if (!res) {
			logout();
			return false;
		} else {
			storeData("token", res);
			set_instance_token(res);
			await profile(res);
			return true;
		}
	};

	const setup = async () => {
		const token = getSpecificData("token");
		if (token) {
			set_instance_token(token);
			await profile(token);
		} else {
			logout();
		}
	};

	useEffect(() => {
		setup();
	}, []);

	const register = async () => {};

	const logout = async () => {
		console.log("logout");
		localStorage.clear();
		unset_instance_token();
		setUser({
			first_name: "Anonymous",
			last_name: "User",
			token: "",
			quizzes: [],
		});
	};

	const is_login = () => {
		return user.token !== null && user.token !== "";
	};

	return (
		<AuthContext.Provider
			value={{
				user,
				login,
				login_google,
				register,
				logout,
				is_login,
				profile,
			}}
		>
			{props.children}
		</AuthContext.Provider>
	);
};

export default AuthContext;
