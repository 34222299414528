import React, { useContext, useEffect } from "react";
import "./style.scss";
import AuthContext from "../../contexts/AuthContext";
import BasicCard from "../../components/plans/specific_card/BasicCard";
import ProCard from "../../components/plans/specific_card/ProCard";
import FreeCard from "../../components/plans/specific_card/FreeCard";
import { max_use_per_plan } from "../../components/constants/main";
import ManageOrigins from "../../components/profile/manage_origins/ManageOrigins";
import { baseURL } from "../../services/axios";

let options = { year: "numeric", month: "long" };
let today = new Date();

const generate_use = (plan, count) => {
	const current_use = count / max_use_per_plan[plan];
	return current_use > 1 ? 100 : current_use * 100;
};

const Profile = () => {
	const { user, profile } = useContext(AuthContext);

	useEffect(() => {
		profile(user.token);
	}, []);

	return (
		<div id="profile">
			<section className="top">
				<div className="container top-content">
					{user.current_plan == "free" ? (
						<FreeCard />
					) : user.current_plan == "basic" ? (
						<BasicCard />
					) : (
						<ProCard />
					)}
					<div className="top-left-content">
						<div className="current-use profile-box">
							<div className="profile-header">
								<h2>Current use</h2>
							</div>

							<span>
								Total avatar load for{" "}
								{today.toLocaleDateString("fr-FR", options)}
							</span>
							<div className="loader-container">
								<div className="total-loader">
									<div
										className="loader"
										style={{
											transform: `translateX(${
												-100 +
												generate_use(
													user.current_plan,
													user.total_loaded
												)
											}%)`,
										}}
									></div>
								</div>
								<div className="loader-count">
									<span>
										{generate_use(
											user.current_plan,
											user.total_loaded
										).toFixed(2)}
										{" %"}
									</span>
									<span>{`${user.total_loaded.toLocaleString()} of ${max_use_per_plan[
										user.current_plan
									].toLocaleString()}`}</span>
								</div>
							</div>
						</div>
						<ManageOrigins />
						<p>{`${baseURL}/avatar/${"classic"}/svg?s=${"randomString"}&u=${
							user.id
						}`}</p>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Profile;
