import React, { useContext, useEffect, useState } from "react";
import { generate_random_string } from "../../helpers/main";
import "./style.scss";
import AuthContext from "../../contexts/AuthContext";
import { api_url, api_user } from "../../constants/constant";
import { baseURL } from "../../services/axios";

const move_with_mouse = (e) => {
	const to_move = document.getElementById("to-move");
	let half = document.body.offsetHeight / 4;
	let move_y = e.pageY - half - window.scrollY;
	let move_x = e.pageX - half;

	move_x =
		move_x < document.body.offsetWidth / 2 - half + 20
			? document.body.offsetWidth / 2 - half + 20
			: move_x > document.body.offsetWidth / 2 - half + 750
			? document.body.offsetWidth / 2 - half + 750
			: move_x;

	move_x = `${move_x}px`;
	move_y = `${move_y}px`;

	to_move.style["-webkit-mask-position"] = `${move_x} ${move_y}`;
};

const clear_mouse = () => {
	const to_move = document.getElementById("to-move");
	let move_x = "calc(var(--round-start) - 25vh)";
	let move_y = `calc(50vh - 25vh)`;

	to_move.style["-webkit-mask-position"] = `${move_x} ${move_y}`;
};

const ImageItem = ({}) => {
	const [reload, setReload] = useState(0);
	const collections = [
		"classic",
		"classic",
		"classic",
		"classic",
		"squared",
		"initials",
	];
	const randomIndex = Math.floor(Math.random() * collections.length);
	const randomString =
		collections[randomIndex] == "initials"
			? generate_random_string(6, "abcdefghijklmnopqrstuvwxyz")
			: generate_random_string(6);

	return (
		<img
			onMouseLeave={() => setReload((reload) => reload + 1)}
			className={`image-item`}
			src={`${baseURL}/avatar/${collections[randomIndex]}/svg?s=${randomString}&u=${api_user}`}
		/>
	);
};

const Home = () => {
	const { user } = useContext(AuthContext);
	const all_images = Array.from(Array(user.token == null ? 0 : 66).keys());

	useEffect(() => {
		window.addEventListener("mousemove", move_with_mouse);

		return () => {
			window.removeEventListener("mousemove", move_with_mouse);
		};
	}, []);

	return (
		<div id="home" onMouseLeave={clear_mouse}>
			<div className="home-background" id="home-background">
				<div className="img-container" id="to-move">
					{all_images.map((elem, id) => (
						<ImageItem key={id} />
					))}
				</div>
			</div>
			<section className="top">
				<div className="container">
					<h1>Avatar Generator</h1>
					<p>Just an other avatar library</p>
				</div>
			</section>
		</div>
	);
};

export default Home;
