export const max_use_per_plan = {
	free: 10000,
	basic: 100000,
	pro: 10000000,
};

export const TOAST_LVL = {
	INFO: "info",
	SUCCESS: "success",
	WARNING: "warning",
	ERROR: "error",
};
