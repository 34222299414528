import React from "react";
import PricingCard from "../pricing_card/PricingCard";
import { useTranslation } from "react-i18next";

const ProCard = ({ yearlyPaid }) => {
	const { t } = useTranslation("home");
	return (
		<PricingCard
			title={t("pricing_unlimited_plan_title")}
			description={t("pricing_unlimited_plan_description")}
			price={yearlyPaid ? 20 : 25}
			include_text={t("pricing_unlimited_plan_include_title")}
			price_id={
				yearlyPaid
					? "price_1NcRelJKfmsW67gb9sKZXZdx"
					: "price_1NcRelJKfmsW67gbgXKgBf03"
			}
			button={t("pricing_unlimited_plan_button")}
			features={[
				t("pricing_unlimited_plan_include_1"),
				t("pricing_unlimited_plan_include_2"),
				t("pricing_unlimited_plan_include_3"),
			]}
			plan={"pro"}
		/>
	);
};

export default ProCard;
