import React, { useContext, useState } from "react";
import "./style.scss";
import { Button, TextField } from "@mui/material";
import { generate_random_string } from "../../../helpers/main";
import { LoadingButton } from "@mui/lab";
import AuthContext from "../../../contexts/AuthContext";
import { useNavigate } from "react-router-dom";
import { api_url, api_user } from "../../../constants/constant";
import {
	GoogleLogin,
	GoogleOAuthProvider,
	useGoogleOneTapLogin,
} from "@react-oauth/google";
import { baseURL } from "../../../services/axios";

const Login = () => {
	const [formData, setFormData] = useState({
		email: "trash.todev@gmail.com",
		password: "Django07",
	});
	const { login, login_google } = useContext(AuthContext);
	const navigate = useNavigate();
	const [loading, setLoading] = useState(false);

	const handle_login = async () => {
		setLoading(true);
		const log = await login(formData.email, formData.password);
		if (log) {
			navigate("/profile");
		}
		setLoading(false);
	};

	return (
		<div id="login">
			<section className="top">
				<div className="container">
					<div className="log-center">
						<img
							src={`${baseURL}/avatar/classic/svg?s=${formData.email}&u=${api_user}`}
						/>
						<h1>Login</h1>

						<form>
							<TextField
								value={formData.email}
								label="Email"
								size="small"
								onChange={(e) =>
									setFormData({
										...formData,
										email: e.target.value,
									})
								}
								variant="outlined"
							/>

							<TextField
								label="Password"
								size="small"
								value={formData.password}
								onSubmit={handle_login}
								onChange={(e) =>
									setFormData({
										...formData,
										password: e.target.value,
									})
								}
								variant="outlined"
							/>
						</form>

						<LoadingButton
							loading={loading}
							onClick={handle_login}
							fullWidth
							variant="outlined"
						>
							Login
						</LoadingButton>
						<GoogleLogin
							onSuccess={async (credentialResponse) => {
								setLoading(true);
								const log = await login_google(
									credentialResponse.credential
								);
								setLoading(false);
								if (log) {
									navigate("/profile");
								}
							}}
							onError={() => {
								console.log("Login Failed");
							}}
						/>
					</div>
				</div>
			</section>
		</div>
	);
};

export default Login;
