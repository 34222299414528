import React from "react";
import "./style.scss";

const Footer = () => {
	return (
		<footer id="footer" className="section">
			<div className="footer-copyright">
				<span>Sigmagency © 2023. All rights reserved</span>
				<span>
					Made by{" "}
					<a target="_blank" href="https://sigmagency.be/">
						Sigmagency
					</a>
				</span>
			</div>
		</footer>
	);
};

export default Footer;
