import React, { useContext, useEffect } from "react";
import "./style.scss";
import { Link, useLocation } from "react-router-dom";
import AuthContext from "../../../contexts/AuthContext";
import { useTranslation } from "react-i18next";
import { api_url, api_user } from "../../../constants/constant";
import { baseURL } from "../../../services/axios";

const NavbarLink = ({ url, label }) => {
	const location = useLocation();

	return (
		<li className={location.pathname === url ? "active" : ""}>
			<Link to={url}>
				<span>{label}</span>
			</Link>
		</li>
	);
};

const scrol_listener = () => {
	let lastScrollTop = 0;
	const header = document.getElementById("navbar");
	window.addEventListener(
		"scroll",
		() => {
			var st = window.pageYOffset;
			if (st > lastScrollTop && window.pageYOffset > 30) {
				header.classList.add("nav-hidden");
			} else {
				if (window.pageYOffset > 60) {
					header.classList.add("sticky");
					if (header.classList.contains("nav-hidden"))
						header.classList.remove("nav-hidden");
				} else if (window.pageYOffset <= 60) {
					if (header.classList.contains("sticky"))
						header.classList.remove("sticky");
					if (header.classList.contains("nav-hidden"))
						header.classList.remove("nav-hidden");
				}
			}
			lastScrollTop = st <= 0 ? 0 : st;
		},
		false
	);
};

const Navbar = () => {
	const { is_login, user } = useContext(AuthContext);

	useEffect(() => {
		window.addEventListener("scroll", scrol_listener);

		return () => {
			window.removeEventListener("scroll", scrol_listener);
		};
	}, []);

	return (
		<header className="section" id="navbar">
			<nav className="container">
				<ul>
					<NavbarLink url="/plans" label={"Plans"} />
				</ul>
				<div>
					<Link to="/">
						<span className="big-title">
							Avatar<span>Generator</span>
						</span>
					</Link>
				</div>
				<ul className={!is_login() ? "profile-padding" : ""}>
					<NavbarLink url="/customizer" label={"Customizer"} />
					{is_login() ? (
						<Link to={"/profile"}>
							<img
								className="profile-picture"
								src={
									user.profile_picture.includes("https")
										? user.profile_picture
										: `${baseURL}/avatar/classic/svg?s=${user.profile_picture}&u=${api_user}`
								}
							/>
						</Link>
					) : (
						<NavbarLink url="/login" label={"Login"} />
					)}
				</ul>
			</nav>
		</header>
	);
};

export default Navbar;
