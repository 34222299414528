import React, { useContext, useState } from "react";
import "./style.scss";
import CheckIcon from "@mui/icons-material/Check";
import { useNavigate } from "react-router-dom";
import axios from "../../../services/axios";
import { LoadingButton } from "@mui/lab";
import { useTranslation } from "react-i18next";
import AuthContext from "../../../contexts/AuthContext";

const PricingCardInclude = ({ feature }) => {
	return (
		<li className="includes-items">
			<CheckIcon />
			<p>{feature}</p>
		</li>
	);
};

const PricingCard = ({
	title,
	description,
	price,
	include_text,
	price_id,
	features,
	popular,
	button,
	plan,
}) => {
	const navigate = useNavigate();
	const { t } = useTranslation("home");
	const [loading, setLoading] = useState(false);
	const { user } = useContext(AuthContext);

	const handleClick = async () => {
		if (!price_id) {
			navigate("/home");
		} else if (user.current_plan != "free") {
			setLoading(true);
			let ret = await axios
				.get("/payments/generate-customer-portal")
				.then((e) => e.data)
				.catch((e) => null);
			if (ret) {
				window.open(ret, "_blank");
			}
			setLoading(false);
		} else {
			setLoading(true);
			const url = await axios
				.get(`/payments/generate-payment-link/${price_id}`)
				.then((r) => r.data)
				.catch((e) => null);
			if (url) {
				window.location.assign(url);
			}
			setLoading(false);
		}
	};

	return (
		<div className="pricing-card">
			<div className="pricing-card-header">
				<span>{title}</span>
				<p>{description}</p>
				<div className="popularity-container">
					{popular ? (
						<div className="popular">
							<span>{t("pricing_popular")}</span>
						</div>
					) : null}
					{user.current_plan == plan ? (
						<div className="current-plan">
							<span>{t("pricing_current_plan")}</span>
						</div>
					) : null}
				</div>
			</div>
			<div className="pricing-card-price">
				<span className="big-price">
					{price}
					<span>€</span>
				</span>
				<span className="by-month">/ {t("pricing_month")}</span>
			</div>

			<LoadingButton
				loading={loading}
				onClick={handleClick}
				variant="contained"
			>
				{user.current_plan == "free" || !user.current_plan
					? button
					: t("pricing_manage")}
			</LoadingButton>
			<div className="pricing-card-includes">
				<span>{include_text}</span>
				<ul className="includes-container">
					{features.map((elem, index) => (
						<PricingCardInclude feature={elem} key={index} />
					))}
				</ul>
			</div>
		</div>
	);
};

export default PricingCard;
