import React, { useState } from "react";
import "./style.scss";
import { Switch } from "@mui/material";
import { useTranslation } from "react-i18next";
import FreeCard from "../../components/plans/specific_card/FreeCard";
import BasicCard from "../../components/plans/specific_card/BasicCard";
import ProCard from "../../components/plans/specific_card/ProCard";

const Pricing = () => {
	const { t } = useTranslation("home");
	const [yearlyPaid, setYearlyPaid] = useState(true);

	return (
		<div id="pricing">
			<section className="top">
				<div className="container pricing-title">
					<h1>{t("pricing_title")}</h1>
					<h2>{t("pricing_subtitle")}</h2>
				</div>
				<div className="monthly-switch">
					<span className={yearlyPaid ? "" : "selected"}>
						{t("pricing_yearly_slide_monthly")}
					</span>
					<Switch
						defaultChecked={true}
						value={yearlyPaid}
						onChange={() => setYearlyPaid(!yearlyPaid)}
					/>
					<span className={!yearlyPaid ? "" : "selected"}>
						{t("pricing_yearly_slide_yearly")}
					</span>
				</div>
			</section>
			<section className="container pricing-table">
				<FreeCard yearlyPaid={yearlyPaid} />
				<BasicCard yearlyPaid={yearlyPaid} />
				<ProCard yearlyPaid={yearlyPaid} />
			</section>
		</div>
	);
};

export default Pricing;
