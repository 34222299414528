// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#pricing {
  padding-bottom: 40px;
}
#pricing .pricing-title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 20px 0 40px;
}
#pricing .pricing-title h1 {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 20px;
}
#pricing .pricing-title h2 {
  font-size: 1rem;
  font-weight: bold;
  color: var(--color-secondary-text);
}
#pricing .monthly-switch {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
}
#pricing .monthly-switch span {
  font-size: 1.2rem;
}
#pricing .monthly-switch .selected {
  font-weight: bold;
}
#pricing .pricing-table {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}`, "",{"version":3,"sources":["webpack://./src/routes/plans/style.scss"],"names":[],"mappings":"AAAA;EACC,oBAAA;AACD;AAAC;EACC,WAAA;EACA,aAAA;EACA,sBAAA;EACA,mBAAA;EACA,uBAAA;EACA,kBAAA;EACA,oBAAA;AAEF;AAAE;EACC,eAAA;EACA,iBAAA;EACA,mBAAA;AAEH;AACE;EACC,eAAA;EACA,iBAAA;EACA,kCAAA;AACH;AAGC;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,uBAAA;EACA,mBAAA;AADF;AAGE;EACC,iBAAA;AADH;AAIE;EACC,iBAAA;AAFH;AAMC;EACC,aAAA;EACA,mBAAA;EACA,uBAAA;EACA,uBAAA;AAJF","sourcesContent":["#pricing {\n\tpadding-bottom: 40px;\n\t.pricing-title {\n\t\twidth: 100%;\n\t\tdisplay: flex;\n\t\tflex-direction: column;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\ttext-align: center;\n\t\tpadding: 20px 0 40px;\n\n\t\th1 {\n\t\t\tfont-size: 2rem;\n\t\t\tfont-weight: bold;\n\t\t\tmargin-bottom: 20px;\n\t\t}\n\n\t\th2 {\n\t\t\tfont-size: 1rem;\n\t\t\tfont-weight: bold;\n\t\t\tcolor: var(--color-secondary-text);\n\t\t}\n\t}\n\n\t.monthly-switch {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: center;\n\t\tjustify-content: center;\n\t\tmargin-bottom: 40px;\n\n\t\tspan {\n\t\t\tfont-size: 1.2rem;\n\t\t}\n\n\t\t.selected {\n\t\t\tfont-weight: bold;\n\t\t}\n\t}\n\n\t.pricing-table {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\talign-items: flex-start;\n\t\tjustify-content: center;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
