import React from "react";
import { useTranslation } from "react-i18next";
import PricingCard from "../pricing_card/PricingCard";

const FreeCard = ({ yearlyPaid }) => {
	const { t } = useTranslation("home");
	return (
		<PricingCard
			title={t("pricing_free_plan_title")}
			description={t("pricing_free_plan_description")}
			price={0}
			include_text={t("pricing_free_plan_include_title")}
			button={t("pricing_free_plan_button")}
			features={[
				t("pricing_free_plan_include_1"),
				t("pricing_free_plan_include_2"),
				t("pricing_free_plan_include_3"),
			]}
			plan={"free"}
		/>
	);
};

export default FreeCard;
