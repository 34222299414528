import React, { useContext, useState } from "react";
import "./style.scss";
import AuthContext from "../../../contexts/AuthContext";
import { Button, IconButton, TextField } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "../../../services/axios";
import CreateBox from "../create_box/CreateBox";
import ConfirmationContext from "../../../contexts/ConfirmationContext";

const DomainItem = ({ domain, remove_from_domains }) => {
	return (
		<div className="domain-item">
			<span>{domain}</span>
			<IconButton
				onClick={remove_from_domains}
				children={<DeleteIcon />}
			/>
		</div>
	);
};

const ManageOrigins = () => {
	const { user, profile } = useContext(AuthContext);
	const [domainsToAdd, setdomainsToAdd] = useState("");
	const [addNewOrigin, setAddNewOrigin] = useState(false);
	const { open_confirm } = useContext(ConfirmationContext);

	const domains =
		user.allowed_origins == ""
			? []
			: user.allowed_origins
					.split("|")
					.filter((e) => e != "" && e != " ");

	const delete_domain = async (id) => {
		domains.splice(id, 1);
		const ret = await axios
			.post("/users/allowed-origins", {
				allowed_origins: ` |${domains.join("|")}`,
			})
			.then((e) => e)
			.catch((e) => null);
		if (ret) {
			await profile(user.token);
		}
	};

	const add_domains = async () => {
		domains.push(...domainsToAdd.split(" "));
		let unique_domains = new Set(domains);
		const new_domains = [...unique_domains].filter((e) => e != "");

		const ret = await axios
			.post("/users/allowed-origins", {
				allowed_origins: ` |${new_domains.join("|")}`,
			})
			.then((e) => e)
			.catch((e) => null);
		if (ret) {
			await profile(user.token);
			setdomainsToAdd("");
			setAddNewOrigin(false);
		}
	};

	return (
		<>
			<CreateBox
				submit_text={`Add domain${
					domainsToAdd.includes(" ") && !domainsToAdd.endsWith(" ")
						? "s"
						: ""
				}`}
				submit={add_domains}
				submitable={domainsToAdd !== ""}
				title={`Add authorize domain`}
				visible={addNewOrigin}
				cancel={() => setAddNewOrigin(false)}
			>
				<div className="add-domain-form">
					<span>
						Si vous souhaitez ajouter plusieurs noms de domaine en
						une fois veuillez les separer d'un espace.
					</span>
					<TextField
						label="Domains"
						fullWidth
						size="small"
						value={domainsToAdd}
						onChange={(e) => setdomainsToAdd(e.target.value)}
					/>
				</div>
			</CreateBox>
			<div id="manage-domains" className="profile-box">
				<div className="domain-header profile-header">
					<h2>Domaines autorisés</h2>
					<Button
						onClick={() => setAddNewOrigin(true)}
						variant="outlined"
					>
						Add domain
					</Button>
				</div>
				<p>
					Pour des raisons de sécurité, vous devez autoriser les
					domaines autorisés à utiliser votre compte AvatarGenerator.
					{domains.length
						? ""
						: " Tant que vous n'avez pas ajouté de domaine, ils sont par défaut TOUS autorisé."}
				</p>
				<div className="domain-container">
					{domains.map((domain, index) => (
						<DomainItem
							remove_from_domains={() =>
								open_confirm(
									"Remove domain",
									"By removing this domain from your authorized domains request from this domain to our api will not be accepted.",
									"",
									() => delete_domain(index)
								)
							}
							domain={domain}
							key={index}
						/>
					))}
				</div>
			</div>
		</>
	);
};

export default ManageOrigins;
