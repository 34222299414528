import { Button, TextField } from "@mui/material";
import React, { useEffect, useRef } from "react";
import { createContext, useState } from "react";
import "./confirmation-context.scss";
import ErrorIcon from "@mui/icons-material/Error";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import InfoIcon from "@mui/icons-material/Info";
import CloseIcon from "@mui/icons-material/Close";
import { TOAST_LVL } from "../components/constants/main";

const ConfirmationContext = createContext();

let type_value = "";

export const ConfirmationProvider = (props) => {
	const set_confirm_ref = useRef(() => null);

	const setToast = useRef(() => null);

	const open_confirm = (title, body = "", confirm_value = "", callback) => {
		set_confirm_ref.current({
			value: confirm_value,
			title: title,
			body: body,
			is_open: true,
			callback: callback,
		});
		document.body.style.overflow = "hidden";
	};

	const close_confirm = () => {
		set_confirm_ref.current({ value: "", is_open: false });
		document.body.style.overflow = "unset";
		type_value = "";
	};

	const Confirm = () => {
		const [confirm, setConfirm] = useState({
			value: "test",
			title: "Delete text message",
			body: "This action cannot be undone. This will permanently delete the selected messages.",
			is_open: false,
			callback: () => null,
		});
		const [reload, setReload] = useState(false);

		useEffect(() => {
			set_confirm_ref.current = setConfirm;
		}, []);

		if (!confirm.is_open) return null;
		return (
			<div className="confirm-view">
				<div className="confirm-view-center">
					<div className="confirm-view-header">
						<h3>{confirm.title}</h3>
					</div>
					<div className="confirm-view-body">
						<p>{confirm.body}</p>
						{confirm.value != "" ? (
							<>
								<p>
									Please type{" "}
									<span className="bold">
										{confirm.value}
									</span>{" "}
									to confirm
								</p>
								<TextField
									size="small"
									defaultValue={type_value}
									onChange={(e) => {
										type_value = e.target.value;
										if (type_value == confirm.value)
											setReload(!reload);
									}}
									fullWidth
									className="value-input"
									id="outlined-basic"
									label={confirm.value}
									variant="outlined"
								/>
							</>
						) : null}
					</div>
					<div className="confirm-view-footer">
						<Button onClick={close_confirm} variant="outlined">
							Cancel
						</Button>
						<Button
							disabled={type_value != confirm.value}
							onClick={() => {
								confirm.callback();
								close_confirm();
							}}
							variant="contained"
						>
							Confirm
						</Button>
					</div>
				</div>
			</div>
		);
	};

	const Toast = () => {
		const [activate, setActivate] = useState(false);
		const [toastsReload, setToastsReload] = useState(null);

		let toasts = useRef({});

		useEffect(() => {
			setToast.current = (level, title, description) => {
				let start_date = new Date().getTime();
				const new_toast = {
					level: level,
					title: title,
					description: description,
					time: start_date,
					inter: null,
				};
				toasts.current[start_date] = new_toast;
				toasts.current[start_date].inter = setTimeout(() => {
					document
						.getElementById(`${start_date}-toast-elem`)
						.classList.add("active");
					toasts.current[start_date].inter = setTimeout(() => {
						if (
							document.getElementById(`${start_date}-toast-elem`)
						) {
							document
								.getElementById(`${start_date}-toast-elem`)
								.classList.remove("active");
							toasts.current[start_date].inter = setTimeout(
								() => {
									delete toasts.current[start_date];
									if (
										document.getElementById(
											`${start_date}-toast-elem`
										)
									)
										document.getElementById(
											`${start_date}-toast-elem`
										).style.display = "none";
									setToastsReload(new Date().getTime());
								},
								300
							);
						}
					}, 10000);
				}, 100);
				setToastsReload(start_date);
			};
		}, []);

		const close = (start_date) => {
			document
				.getElementById(`${start_date}-toast-elem`)
				.classList.remove("active");
			clearTimeout(toasts.current[start_date].inter);
			setTimeout(() => {
				delete toasts.current[start_date];
				document.getElementById(
					`${start_date}-toast-elem`
				).style.display = "none";
				setToastsReload(new Date().getTime());
			}, 300);
		};

		useEffect(() => {}, [toastsReload]);

		return (
			<div className="toasts-container">
				{Object.keys(toasts.current).map((elem, id) => {
					const toast = toasts.current[elem];
					return (
						<div
							id={`${toast.time}-toast-elem`}
							key={id}
							className={`toast ${toast.level}`}
						>
							<div className="row-toast">
								{toast.level == TOAST_LVL.INFO ? (
									<InfoIcon />
								) : TOAST_LVL.SUCCESS == toast.level ? (
									<CheckCircleIcon />
								) : (
									<ErrorIcon />
								)}
								<div className="toast-content">
									<span>{toast.title}</span>
									<p>{toast.description}</p>
								</div>
							</div>
							<CloseIcon
								onClick={() => close(toast.time)}
								className="close-btn"
							/>
						</div>
					);
				})}
			</div>
		);
	};

	return (
		<ConfirmationContext.Provider
			value={{
				open_confirm,
				close_confirm,
				setToast,
			}}
		>
			<Toast />
			<Confirm />
			{props.children}
		</ConfirmationContext.Provider>
	);
};

export default ConfirmationContext;
