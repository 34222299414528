import axios from "axios";

export const baseURL =
	process.env.REACT_APP_SERVER === "remote"
		? process.env.REACT_APP_ENVIRONMENT === "prod"
			? process.env.REACT_APP_PROD_URL
			: process.env.REACT_APP_STAGING_URL
		: process.env.REACT_APP_ENVIRONMENT === "prod"
		? process.env.REACT_APP_PROD_LOCAL_URL
		: process.env.REACT_APP_STAGING_LOCAL_URL;

const instance = axios.create({
	baseURL: `${baseURL}/auth/`,
	headers: {
		"Content-Type": "application/json",
	},
	timeout: 10000,
});

export default instance;
