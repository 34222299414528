import React from "react";
import ReactDOM from "react-dom/client";
import "./index.scss";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { AuthProvider } from "./contexts/AuthContext";
import "./i18n";
import { ConfirmationProvider } from "./contexts/ConfirmationContext";
import { GoogleOAuthProvider } from "@react-oauth/google";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
	<AuthProvider>
		<ConfirmationProvider>
			<GoogleOAuthProvider clientId="1032685767266-rmhgi7dur8dh20m0ht8si56vesv4vecg.apps.googleusercontent.com">
				<App />
			</GoogleOAuthProvider>
		</ConfirmationProvider>
	</AuthProvider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
