// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#login section.top {
  min-height: 100vh;
  padding-bottom: 80px;
}
#login section.top .container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#login section.top .container .log-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
#login section.top .container .log-center h1 {
  margin: 20px 0;
}
#login section.top .container .log-center form {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}
#login section.top .container .log-center form > * + * {
  margin-top: 15px;
}
#login section.top .container .log-center img {
  width: 96px;
  height: 96px;
  border-radius: 50%;
}`, "",{"version":3,"sources":["webpack://./src/routes/auth/login/style.scss"],"names":[],"mappings":"AACC;EACC,iBAAA;EACA,oBAAA;AAAF;AAEE;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;AAAH;AAEG;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;AAAJ;AAEI;EACC,cAAA;AAAL;AAGI;EACC,aAAA;EACA,sBAAA;EACA,mBAAA;AADL;AAGK;EACC,gBAAA;AADN;AAKI;EACC,WAAA;EACA,YAAA;EACA,kBAAA;AAHL","sourcesContent":["#login {\n\tsection.top {\n\t\tmin-height: 100vh;\n\t\tpadding-bottom: 80px;\n\n\t\t.container {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: column;\n\t\t\talign-items: center;\n\n\t\t\t.log-center {\n\t\t\t\tdisplay: flex;\n\t\t\t\tflex-direction: column;\n\t\t\t\talign-items: center;\n\n\t\t\t\th1 {\n\t\t\t\t\tmargin: 20px 0;\n\t\t\t\t}\n\n\t\t\t\tform {\n\t\t\t\t\tdisplay: flex;\n\t\t\t\t\tflex-direction: column;\n\t\t\t\t\tmargin-bottom: 20px;\n\n\t\t\t\t\t& > * + * {\n\t\t\t\t\t\tmargin-top: 15px;\n\t\t\t\t\t}\n\t\t\t\t}\n\n\t\t\t\timg {\n\t\t\t\t\twidth: 96px;\n\t\t\t\t\theight: 96px;\n\t\t\t\t\tborder-radius: 50%;\n\t\t\t\t}\n\t\t\t}\n\t\t}\n\t}\n}\n\n//coucouhow are you>\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
