// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `#manage-domains {
  width: 100%;
}
#manage-domains .domain-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
#manage-domains .domain-container {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: flex-start;
  margin-top: 10px;
}
#manage-domains .domain-container .domain-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius);
  box-shadow: var(--box-shadow);
  background-color: var(--color-surface);
  padding: 2px 5px 2px 15px;
  margin: 8px;
}
#manage-domains .domain-container .domain-item span {
  margin-right: 10px;
}

.add-domain-form {
  padding: 10px 20px;
}
.add-domain-form span {
  display: block;
  margin-bottom: 10px;
}`, "",{"version":3,"sources":["webpack://./src/components/profile/manage_origins/style.scss"],"names":[],"mappings":"AAAA;EACC,WAAA;AACD;AACC;EACC,aAAA;EACA,mBAAA;EACA,8BAAA;EACA,WAAA;AACF;AAEC;EACC,aAAA;EACA,mBAAA;EACA,eAAA;EACA,uBAAA;EACA,2BAAA;EACA,gBAAA;AAAF;AAEE;EACC,aAAA;EACA,mBAAA;EACA,mBAAA;EACA,8BAAA;EACA,mCAAA;EACA,6BAAA;EACA,sCAAA;EACA,yBAAA;EACA,WAAA;AAAH;AAEG;EACC,kBAAA;AAAJ;;AAMA;EACC,kBAAA;AAHD;AAKC;EACC,cAAA;EACA,mBAAA;AAHF","sourcesContent":["#manage-domains {\n\twidth: 100%;\n\n\t.domain-header {\n\t\tdisplay: flex;\n\t\talign-items: center;\n\t\tjustify-content: space-between;\n\t\twidth: 100%;\n\t}\n\n\t.domain-container {\n\t\tdisplay: flex;\n\t\tflex-direction: row;\n\t\tflex-wrap: wrap;\n\t\talign-items: flex-start;\n\t\tjustify-content: flex-start;\n\t\tmargin-top: 10px;\n\n\t\t.domain-item {\n\t\t\tdisplay: flex;\n\t\t\tflex-direction: row;\n\t\t\talign-items: center;\n\t\t\tjustify-content: space-between;\n\t\t\tborder-radius: var(--border-radius);\n\t\t\tbox-shadow: var(--box-shadow);\n\t\t\tbackground-color: var(--color-surface);\n\t\t\tpadding: 2px 5px 2px 15px;\n\t\t\tmargin: 8px;\n\n\t\t\tspan {\n\t\t\t\tmargin-right: 10px;\n\t\t\t}\n\t\t}\n\t}\n}\n\n.add-domain-form {\n\tpadding: 10px 20px;\n\n\tspan {\n\t\tdisplay: block;\n\t\tmargin-bottom: 10px;\n\t}\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
