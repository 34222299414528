import React from "react";
import "./style.scss";
import loading_animation from "../../../assets/lotties/loading.json";
import Lottie from "lottie-react";

const Loading = ({ full_screen = false }) => {
	return (
		<div
			id="loading-main-view"
			className={full_screen ? "full-screen" : ""}
		>
			<Lottie className="lotties" animationData={loading_animation} />
		</div>
	);
};

export default Loading;
