import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { baseURL } from "../../services/axios";
import "./style.scss";
import { useTranslation } from "react-i18next";
import { generate_random_string } from "../../helpers/main";
import { Button, IconButton, Skeleton } from "@mui/material";
import ReplayIcon from "@mui/icons-material/Replay";
import { api_url, api_user } from "../../constants/constant";
import loading_animation from "../../assets/lotties/avatar_lotties.json";
import Lottie from "lottie-react";

const valueFromHash = (s, maxOutput) => {
	const twoB = 16;
	const size = s.length;
	const maxValue = twoB ** size;
	const numbers = "0123456789";
	let value = 0;

	for (let i = 0; i < s.length; i++) {
		const ch = s[i];
		if (numbers.includes(ch)) {
			value += (ch.charCodeAt(0) - 48) * twoB ** (size - 1 - i);
		} else {
			value += (ch.charCodeAt(0) - 87) * twoB ** (size - 1 - i);
		}
	}

	return Math.floor((value * (maxOutput + 1)) / maxValue);
};

const reverseValueFromHash = (maxOutput, size, int) => {
	if (maxOutput == 0) return "0" * size;
	const twoB = 16;
	const maxValue = twoB ** size;
	let value = Math.floor((int * maxValue) / maxOutput) + 1;
	let result = "";
	const numbers = "0123456789";
	const alphabet = "abcdefghijklmnopqrstuvwxyz";

	for (let i = 0; i < size; i++) {
		let power = twoB ** (size - 1 - i);
		let digitValue = Math.floor(value / power);

		value -= digitValue * power;

		if (digitValue < 10) {
			result += numbers[digitValue];
		} else {
			result += alphabet[(digitValue <= 15 ? digitValue : 15) - 10];
		}
	}

	return result;
};

const CustomizerItem = ({
	item,
	spec_hash,
	set_new,
	preview,
	reset,
	correct_string,
	collection,
}) => {
	const { t } = useTranslation("customizer");

	const hash_color =
		spec_hash.length == 4 ? spec_hash.substring(2) : spec_hash;
	const hash_type =
		spec_hash.length == 4 ? spec_hash.substring(0, 2) : spec_hash;

	const current_type = valueFromHash(hash_type, item.spec_max_value);
	const current_color = valueFromHash(hash_color, item.spec_max_color);

	const set_value = (value, is_color) => {
		const new_hash = reverseValueFromHash(
			is_color ? item.spec_max_color : item.spec_max_value,
			2,
			value
		);
		set_new(
			new_hash,
			spec_hash.length == 4 && is_color
				? item.start_index + 2
				: item.start_index
		);
	};

	const set_preview = (value, is_color) => {
		const new_hash = reverseValueFromHash(
			is_color ? item.spec_max_color : item.spec_max_value,
			2,
			value
		);
		preview(
			new_hash,
			spec_hash.length == 4 && is_color
				? item.start_index + 2
				: item.start_index
		);
	};

	const get_specific_value = (id) => {
		const new_hash = reverseValueFromHash(item.spec_max_value, 2, id);

		const new_string =
			correct_string.slice(0, item.start_index + 8) +
			new_hash +
			correct_string.slice(item.start_index + 10);

		return new_string;
	};

	return (
		<div className="customizer-item">
			<h3>{t(`${item.name}_title`)}</h3>
			{item.spec_values ? (
				<>
					<h4>{t(`${item.name}_type`)}</h4>
					<div onMouseLeave={reset} className="type-slider">
						{item.spec_values.map((elem, id) => (
							<div
								onClick={() => set_value(id, false)}
								onMouseEnter={() => set_preview(id, false)}
								className={`type-slider__item ${
									current_type == id ? "selected" : ""
								}`}
								key={id}
							>
								<img
									src={`${baseURL}/avatar/${collection}/svg?s=${get_specific_value(
										id
									)}&u=${api_user}`}
								/>
							</div>
						))}
					</div>
				</>
			) : null}
			{item.spec_colors ? (
				<>
					<h4>{t(`${item.name}_color`)}</h4>
					<div onMouseLeave={reset} className="color-selector">
						{item.spec_colors.map((elem, id) => (
							<div
								onClick={() => set_value(id, true)}
								onMouseEnter={() => set_preview(id, true)}
								className={`color-selector__item ${
									current_color == id ? "selected" : ""
								}`}
								style={{ backgroundColor: elem }}
								key={id}
							></div>
						))}
					</div>
				</>
			) : null}
		</div>
	);
};

const Customizer = () => {
	let correct_string = useRef(
		`@custom@${generate_random_string(24, "0123456789abcdef")}`
	);
	const [mainSeed, setmainSeed] = useState(correct_string.current);
	const [specification, setSpecifications] = useState([]);
	const [reload, setReload] = useState(false);
	const collection = useRef("classic");

	const load_spec = async () => {
		const ret = await axios
			.get(`${baseURL}/avatar/${collection.current}/specs`)
			.then((e) => e.data)
			.catch((e) => {
				console.log(e);
				return null;
			});

		if (ret) {
			setSpecifications(ret);
		}
	};

	const update_spec = (new_value, start) => {
		const new_string =
			correct_string.current.slice(0, start + 8) +
			new_value +
			correct_string.current.slice(start + 10);
		correct_string.current = new_string;
		setmainSeed(new_string);
		setReload(!reload);
	};

	const preview = (new_value, start) => {
		const new_string =
			correct_string.current.slice(0, start + 8) +
			new_value +
			correct_string.current.slice(start + 10);
		setmainSeed(new_string);
	};

	const copy_to_clipboard = (is_url = false) => {
		navigator.clipboard.writeText(
			is_url
				? `${baseURL}/avatar/${collection.current}/svg?s=${mainSeed}&u=${api_user}`
				: mainSeed
		);
	};

	function download(source) {
		const fileName = source.split("/").pop();
		var el = document.createElement("a");
		el.setAttribute("href", source);
		el.setAttribute("download", fileName);
		document.body.appendChild(el);
		el.click();
		el.remove();
	}

	useEffect(() => {
		load_spec();
	}, []);

	const int_str = correct_string.current.substring(8);

	if (!specification.length) {
		return (
			<div id="customizer">
				<div className="loader">
					<Lottie
						className="lotties"
						animationData={loading_animation}
					/>
				</div>
			</div>
		);
	}

	return (
		<div id="customizer">
			<section className="top">
				<div className="container customizer-title">
					<h1>Customize your avatar</h1>
				</div>
				<div>
					{["classic", "realistic"].map((elem, id) => (
						<div className="clicker" key={id}>
							<h2
								onClick={() => {
									collection.current = elem;
									load_spec();
								}}
							>
								{elem}
							</h2>
						</div>
					))}
				</div>
			</section>
			<section>
				<div className="container customizer-container">
					<div className="customizer-header">
						<div className="image-loader">
							<img
								src={`${baseURL}/avatar/${collection.current}/svg?s=${mainSeed}&u=${api_user}`}
							/>
						</div>
						<div className="clipboard-items">
							<Button
								onClick={() => copy_to_clipboard(true)}
								variant="contained"
								color="inherit"
							>
								Copy URL
							</Button>
							<Button
								onClick={() => copy_to_clipboard(false)}
								variant="contained"
								color="inherit"
							>
								Copy SEED
							</Button>
							<Button
								onClick={() =>
									download(
										`${baseURL}/avatar/${collection.current}/svg?s=${mainSeed}&u=${api_user}`
									)
								}
								variant="contained"
								color="inherit"
							>
								DOWNLOAD
							</Button>
						</div>
					</div>
					<div className="customizer-body">
						<IconButton
							onClick={() => {
								correct_string.current = `@custom@${generate_random_string(
									24,
									"0123456789abcdef"
								)}`;
								setmainSeed(correct_string.current);
							}}
							className="randomizer"
							children={<ReplayIcon />}
						/>
						{specification.map((elem, id) => (
							<CustomizerItem
								item={elem}
								spec_hash={int_str.substring(
									elem.start_index,
									elem.end_index
								)}
								key={id}
								set_new={update_spec}
								preview={preview}
								correct_string={correct_string.current}
								collection={collection.current}
								reset={() =>
									setmainSeed(correct_string.current)
								}
							/>
						))}
					</div>
				</div>
			</section>
		</div>
	);
};

export default Customizer;
