export const generate_random_string = (len, str_set = "") => {
	if (str_set == "") {
		return Math.random()
			.toString(36)
			.substring(2, len + 2);
	}

	let result = "";
	const setLength = str_set.length;

	for (let i = 0; i < len; i++) {
		const randomIndex = Math.floor(Math.random() * setLength);
		result += str_set[randomIndex];
	}

	return result;
};
